.App {
  text-align: center;
  /* width: max-content; */
  min-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  background-color: #282c34;
  /* min-height: 100vh; */
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-section {
  margin: 0 auto;
  width: 20rem;
}
.form {
  margin: 2rem;
}
.form-control {
  display: flex;
  flex-direction: column;
}
button {
  padding: 0.5rem 3rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}
.table {
  width: max-content;
}
.table td {
  padding: .25rem;
}
.border-4 {
  border-width: 4px !important;
}
.btn-config {
  position: fixed;
  top: 20px;
  right: 5px;
}
main {
  margin-top: 100px;
}